import React, { useState, HTMLAttributes } from 'react'
import loadable from '@loadable/component'
import styled, { css } from 'styled-components'
import { navigate } from 'gatsby'
import { breakpoints, useResolution, Anchor, City, capitalize } from '@ecommerce/shared'
import { PageContainer } from '../components/PageContainer'
import { Icon } from '../components/Icon/Icon'
import { baseMenuItems } from '../components/MyAccountSidebar/menuItems'
import LocationSelectorWidget from '../components/LocationSelector'
import { MenuTile, tileBoxStyles, MenuTileProps } from '../components/Sidebar/MenuTile'
import withPageTransition from '../components/withPageTransition'
import useSession from '../hooks/useSession'

const ConfirmationAlert = loadable(() => import('../components/ConfirmationAlert'))

const cssPrefix = `UserMenu__`

const Wrapper = styled.div`
  @media (${breakpoints.desktop.min}) {
    &page {
      padding: 0px;
    }
    .${cssPrefix} {
      &page {
        padding: 37px 28px 48px 38px;
      }
    }
  }
`

const activeTile = css`
  background-color: ${(props) => props.theme.colors.medium50};
`

const InnerContainer = styled.div<{ hasActiveCustomer?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  .${cssPrefix} {
    &content {
      position: relative;

      &-container {
        display: flex;
        flex-direction: column;
      }
      &-title {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 25px;
      }
    }
  }
  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &menu {
        width: 286px;
        margin-right: 36px;
        margin-top: 78px;
        &-customer-tile {
          font-size: 18px;
          border-top: 2px solid ${(props) => props.theme.colors.dark10};
          font-weight: bold;
          width: 100%;
          color: ${(props) => props.theme.colors.black};
          ${(props) => (props.hasActiveCustomer ? activeTile : '')};
          display: flex;
          align-items: center;
          padding-left: 17px;
          ${tileBoxStyles}
          > span {
            margin-left: 11px;
          }
        }
        &-tile {
          &-active {
            ${activeTile}
          }
        }
      }

      &content {
        flex-grow: 1;
        display: flex;
        flex-direction: column;
        &-desktop-bar {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 12.5px;
          margin-bottom: 22px;

          &-title {
            font-weight: bold;
            font-size: 24px;
          }

          &-logout {
            display: flex;
            align-items: center;
            cursor: pointer;
            color: ${(props) => props.theme.colors.black};

            &-text {
              font-size: 18px;
              line-height: 22px;
              letter-spacing: -0.25px;
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
`

interface UserMenuProps extends HTMLAttributes<HTMLDivElement> {
  title: string
  customerName: string
  onSignOut?: () => void
  contentClassName?: string
  titleClassName?: string
  topBarClassName?: string
  currentCity?: City
  activeMenuIndex?: number
  goBackElement?: React.ReactNode
  cartHasProducts?: boolean
}

const UserMenu = (props: UserMenuProps) => {
  const {
    title,
    contentClassName,
    titleClassName,
    topBarClassName,
    currentCity,
    activeMenuIndex,
    customerName,
    ...restProps
  } = props

  const { resetAppState } = useSession()

  const defaultSignOut = async () => {
    await resetAppState(`/${currentCity?.slug}`)
  }
  const onSignOut = props.onSignOut || defaultSignOut

  const [isOpenSignOutAlert, setIsOpenSignOutAlert] = useState(false)
  const [isOpenLocationSelector, setIsOpenLocationSelector] = useState(false)

  const { isDesktop } = useResolution()

  const formatMenuItemText = (item: MenuTileProps) => {
    if (item.actionType && item.actionType === 'location' && item.text.includes('$location')) {
      const arr = item.text.split('$location')
      return arr.join(currentCity ? capitalize(currentCity.name) : '')
    }
    return item.text
  }

  const getMenuTileClassNames = (idx: number) => {
    const className = `${cssPrefix}menu-tile`
    if (activeMenuIndex && activeMenuIndex - 1 === idx) return `${className} ${cssPrefix}menu-tile-active`
    return className
  }

  const handleMenuItemClick = (item: MenuTileProps) => {
    if (item.actionType) {
      switch (item.actionType) {
        case 'navigation':
          if (item.slug && currentCity) navigate(`/${currentCity.slug}/${item.slug}`)
          break
        case 'signout':
          onSignOut()
          break
        case 'location':
          setIsOpenLocationSelector(true)
          break
        default:
          break
      }
    }
  }

  return (
    <Wrapper {...restProps}>
      <PageContainer
        cardMinWidthDesktop="1025px"
        cardMaxWidthDesktop="1230px"
        cardWidthDesktop="1230px"
        className={`${cssPrefix}page`}
      >
        <InnerContainer hasActiveCustomer={activeMenuIndex === 0}>
          <div className={`${cssPrefix}menu`}>
            {isDesktop && (
              <>
                <Anchor
                  className={`${cssPrefix}menu-customer-tile`}
                  onClick={() => (currentCity ? navigate(`/${currentCity.slug}/my-account`) : null)}
                >
                  <Icon iconId="user" size="25" />
                  <span>{customerName}</span>
                </Anchor>
                {baseMenuItems.map((item, idx) => (
                  <MenuTile
                    key={item.iconId}
                    className={getMenuTileClassNames(idx)}
                    iconId={item.iconId}
                    text={formatMenuItemText(item)}
                    data-test={item.dataTest || ''}
                    onClick={() => handleMenuItemClick(item)}
                  />
                ))}
              </>
            )}
          </div>
          <div className={`${cssPrefix}content`}>
            {isDesktop ? (
              <div className={`${cssPrefix}content-desktop-bar ${topBarClassName}`}>
                <div className={`${cssPrefix}content-desktop-bar-title ${titleClassName}`}>
                  <span className={`${cssPrefix}content-desktop-bar-title-span`}>{title}</span>
                </div>
                <div className={`${cssPrefix}content-go-back`}>{props.goBackElement || ''}</div>
                <Anchor
                  className={`${cssPrefix}content-desktop-bar-logout`}
                  onClick={() => setIsOpenSignOutAlert(true)}
                >
                  <Icon className={`${cssPrefix}content-desktop-bar-logout-icon`} size="21" iconId="logout" />
                  <span className={`${cssPrefix}content-desktop-bar-logout-text`}>Cerrar sesión</span>
                </Anchor>
              </div>
            ) : (
              <div className={`${cssPrefix}content-title ${titleClassName}`}>
                <span className={`${cssPrefix}content-title-span`}>{title}</span>
              </div>
            )}
            <div className={`${cssPrefix}content-container ${contentClassName}`}>{props.children}</div>
          </div>
        </InnerContainer>
      </PageContainer>
      {isOpenSignOutAlert && (
        <ConfirmationAlert
          onClose={() => setIsOpenSignOutAlert(false)}
          onBlur={() => setIsOpenSignOutAlert(false)}
          onConfirm={() => onSignOut()}
          confirmButtonText="Cerrar sesión"
          text="¿Estás seguro de querer cerrar tu sesión?"
        />
      )}
      {isOpenLocationSelector && (
        <LocationSelectorWidget onClose={() => setIsOpenLocationSelector(false)} requireConfirmation />
      )}
    </Wrapper>
  )
}

export default withPageTransition(UserMenu)
