import React, { useEffect, useState, ReactText } from 'react'
import styled from 'styled-components'
import loadable from '@loadable/component'
import { navigate } from 'gatsby'
import {
  breakpoints,
  getAuth,
  sendDataToGTM,
  useAuth,
  useLocation,
  useResolution,
  useShoppingCart,
  getDniBoliviaSchema,
  showToast,
} from '@ecommerce/shared'
import * as Yup from 'yup'
import { FormProvider, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers'
import EditBolivia from '../components/MyAccount/Edit/EditBolivia/EditBolivia'
import Layout from '../components/Layout'
import { PgPageProps } from '../types/PgPages'
import { UserMenuMobileNavbar } from '../components/NavBar/UserMenuMobileNavbar'
import UserMenu from '../templates/UserMenu'
import withPageTransition from '../components/withPageTransition'
import EditChile from '../components/MyAccount/Edit/EditChile/EditChile'
import { sendMessageToSentry, ErrorLevel } from '../utils/sentry'

const ConfirmationAlert = loadable(() => import('../components/ConfirmationAlert'))

const cssPrefix = `MyAccount__`
const Wrapper = styled.div`
  .${cssPrefix} {
    &container {
      display: flex;
      flex-direction: column;
      width: 320px;
    }

    &container {
      &-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme.colors.medium50};
        padding: 16px 19.5px;
        margin-bottom: 24px;
        border-radius: 5px;

        &-img {
          width: 78px;
          height: 78px;
          margin-right: 16px;
        }

        p {
          text-align: center;
          font-size: 15px;
          line-height: 20px;
          letter-spacing: -0.15px;
          margin-bottom: 10px;
          span {
            font-weight: bold;
          }
        }

        &-submit {
          width: 205px;
          min-width: 205px;
          display: flex;
          align-items: center;
          padding: 0 16px;
          gap: 16px;

          span {
            font-size: 17px;
            line-height: 25px;
            letter-spacing: -0.24px;
          }
        }

        @media (${breakpoints.desktop.min}) {
          flex-direction: row;
          align-items: center;
          padding: 11px 23px;
          justify-content: space-between;

          p {
            width: 371px;
            text-align: justify;
            margin-right: 40px;
            padding-right: 20px;
          }
        }
      }
    }

    &container-bottom {
      display: flex;
      flex-direction: column;
      gap: 24px;

      &-left {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }

      &-right {
        display: flex;
        flex-direction: column;
        gap: 24px;
      }
    }

    &password-container {
      margin-bottom: 20px;
      .${cssPrefix}password-reset {
        text-align: right;
        position: relative;
        .${cssPrefix}reset-link {
          position: absolute;
          margin-left: -100px;
          margin-top: -30px;
          font-size: 13px;
          color: ${(props) => props.theme.colors.primary};
          font-weight: bold;
          cursor: pointer;
        }
      }
    }
    &city-selector {
      margin-top: 20px;
      margin-bottom: 20px;
    }
    &birthdate {
      &-container {
        margin-bottom: 30px;
        @media (${breakpoints.desktop.min}) {
          margin-bottom: 65px;
        }
      }
      &-label {
        font-size: 0.75rem;
        line-height: 1.2;
        margin-bottom: 0.5rem;
      }
      &-fields {
        display: flex;
        justify-content: space-between;
        &-date {
          max-width: 70px;
          margin-right: 10px;
        }
        &-month {
          max-width: 138px;
          margin-right: 10px;
        }
        &-year {
          max-width: 80px;
        }
      }
    }
    &submit {
      &-container {
        width: 100%;
        display: flex;
        justify-content: center;
      }
      &-button {
        width: 228px;
        max-width: 228px;
        min-width: 100px;
      }
    }
    &error-status {
      display: flex;
      justify-content: center;
      height: 34px;
      &-message {
        width: 228px;
        max-width: 228px;
        min-width: 100px;
        margin-top: 20px;
        color: ${(props) => props.theme.colors.error};
        svg {
          fill: ${(props) => props.theme.colors.error};
        }
      }
    }

    &checkbox {
      margin-top: -18px;
      align-items: center;
      p {
        font-size: 13px;
        line-height: 18px;
        letter-spacing: -0.06px;
      }
    }
  }

  @media (${breakpoints.desktop.min}) {
    .${cssPrefix} {
      &template-content {
        height: 100%;
        min-height: 100%;
        max-height: 100%;
      }

      &container {
        width: 95%;
      }

      &container-bottom {
        display: flex;
        flex-direction: row;
        width: 100%;
        max-width: 100%;
        min-width: 100%;
        gap: 44px;
        &-left {
          width: calc(50% - 20px);
          padding: 21px 18px 104px 21px;
          border: 1px solid ${(props) => props.theme.colors.dark10};
          border-radius: ${(props) => props.theme.borderRadius};
        }
        &-right {
          width: calc(50% - 22px);
          padding: 21px 18px;
          border: 1px solid ${(props) => props.theme.colors.dark10};
          border-radius: ${(props) => props.theme.borderRadius};
        }
      }
    }
  }
`

interface FormData {
  firstName: string
  lastName: string
  dni: string
  email: string
  city: number | string
  birthdate: string
  billingName: string
  documentType: string
  dniComplement: string
  isUpdated: boolean
  ownerId: string
  registered: boolean
}

const getFormSchema = (isBolivia = false, type: string) => {
  if (!isBolivia) return Yup.object().shape({})
  return Yup.object().shape({
    firstName: Yup.string().required('Debes completar este campo'),
    lastName: Yup.string().required('Debes completar este campo'),
    email: Yup.string().email('El formato del email no es el correcto').required('Debes completar este campo'),
    billingName: Yup.string().required('Debes completar este campo'),
    dni: getDniBoliviaSchema(type).dni,
    dniComplement: getDniBoliviaSchema(type).dniComplement,
  })
}

const defaultValues: FormData = {
  billingName: '',
  birthdate: '',
  city: '' || 0,
  dniComplement: '',
  documentType: '',
  firstName: '',
  lastName: '',
  dni: '',
  email: '',
  isUpdated: false,
  ownerId: '',
  registered: false,
}

const MyAccount = (props: PgPageProps) => {
  const { currentCity, productCategories } = props.pageContext
  const currentSlug = currentCity && currentCity.slug ? currentCity.slug : 'vina-del-mar'

  const { state: authState, onCustomerUpdate } = useAuth()

  const { firstName, lastName, city, birthdate } = authState

  const [selectedDocumentType, setSelectedDocumentType] = useState<string>('')

  const isAuth = getAuth()

  const {
    state: { cities },
    isBolivia,
  } = useLocation()

  const { isDesktop } = useResolution()

  const {
    state: { globalQuantity },
    reset: resetCart,
  } = useShoppingCart()

  const methods = useForm<FormData>({
    resolver: yupResolver(getFormSchema(isBolivia(), selectedDocumentType)),
    defaultValues,
    mode: 'all',
  })

  const { register, reset, setValue, trigger, getValues } = methods

  const bdate = { date: '', month: '', year: '' }
  if (birthdate && birthdate.split('/').length === 3) [bdate.date, bdate.month, bdate.year] = birthdate.split('/')

  useEffect(() => {
    setSelectedDocumentType(getValues().documentType ?? 'ci')
  }, [])

  useEffect(() => {
    register({ name: 'documentType' })
    register({ name: 'birthdate' })
    register({ name: 'ownerId' })
    register({ name: 'isUpdated' })
    register({ name: 'billingName' })
    register({ name: 'registered' })
  }, [register])

  useEffect(() => {
    reset({
      ...authState,
      documentType: authState.documentType ?? 'ci',
    })
  }, [reset])

  useEffect(() => {
    if (!isAuth) navigate(`/${currentSlug}`)
  }, [isAuth])

  const [alertState, setAlertState] = useState({ isOpenSignOut: false, isOpenSubmit: false })
  const [isLoading, setIsLoading] = useState(false)
  const [apiError, setApiError] = useState<string | null>(null)

  const handleSubmit = () => {
    setIsLoading(true)
    setAlertState({ isOpenSubmit: false, isOpenSignOut: false })
    onCustomerUpdate({
      city: parseInt(getValues().city.toString(), 10),
      birthdate: getValues().birthdate,
      ownerId: getValues().ownerId,
      dni: getValues().dni,
      dniComplement: getValues().dniComplement,
      lastName: getValues().lastName,
      documentType: isBolivia() ? getValues().documentType : undefined,
      isUpdated: isBolivia() ? isBolivia() : undefined,
      firstName: getValues().firstName,
      billingName: isBolivia() ? getValues().billingName : undefined,
      registered: getValues().registered,
    })
      .then(() => {
        showToast({
          title: 'Datos actualizados',
          content: 'Los datos de tu cuenta se han actualizado y guardado exitosamente',
          type: 'success',
        })
        setIsLoading(false)
        resetCart()
      })
      .catch((e) => {
        showToast({ title: 'Ha habido un error', content: 'Por favor intenta de nuevo mas tarde.', type: 'error' })
        sendMessageToSentry({
          message: `error: ${e?.message ?? ''}`,
          level: ErrorLevel.Warning,
          page: 'edit user',
          metadata: { error: { ...e } },
        })
        if (e?.description) setApiError(e.description)
        else setApiError('Ha ocurrido un error de conexión')
      })
      .finally(() => setIsLoading(false))
  }

  useEffect(() => {
    sendDataToGTM({ event: 'userProfile' })
  }, [])

  const onCityChange = async (selected: string) => {
    setValue('city', parseInt(selected.valueOf().toString(), 10), { shouldDirty: true })
    await trigger('city')
  }

  return (
    <Layout
      currentCity={currentCity}
      title="Mis Pedidos"
      navbar={isDesktop ? undefined : UserMenuMobileNavbar({ city: currentCity, onLogoClick: () => navigate('/') })}
      categories={productCategories}
    >
      <Wrapper>
        <UserMenu
          currentCity={currentCity}
          title="Mi Cuenta"
          customerName={`${firstName} ${lastName}`}
          contentClassName={`${cssPrefix}template-content`}
          activeMenuIndex={0}
          cartHasProducts={globalQuantity > 0}
        >
          <div className={`${cssPrefix}container`}>
            <FormProvider {...methods}>
              {isBolivia() ? (
                <EditBolivia
                  selectedDocumentType={selectedDocumentType}
                  setSelectedDocumentType={setSelectedDocumentType}
                  apiError={apiError}
                  globalQuantity={globalQuantity}
                  bdate={bdate}
                  cssPrefix={cssPrefix}
                  currentSlug={currentSlug}
                  onCityChange={onCityChange}
                  cities={cities}
                  isLoading={isLoading}
                  setAlertState={setAlertState}
                  alertState={alertState}
                  handleSubmit={handleSubmit}
                />
              ) : (
                <EditChile
                  apiError={apiError}
                  globalQuantity={globalQuantity}
                  bdate={bdate}
                  cssPrefix={cssPrefix}
                  currentSlug={currentSlug}
                  onCityChange={onCityChange}
                  cities={cities}
                  isLoading={isLoading}
                  setAlertState={setAlertState}
                  alertState={alertState}
                  handleSubmit={handleSubmit}
                />
              )}
            </FormProvider>
            {alertState.isOpenSubmit && (
              <ConfirmationAlert
                onClose={() => setAlertState({ ...alertState, isOpenSubmit: false })}
                onBlur={() => setAlertState({ ...alertState, isOpenSubmit: false })}
                onConfirm={() => setAlertState({ ...alertState, isOpenSubmit: false })}
                onCancel={() => handleSubmit()}
                confirmButtonText="Mantener ciudad"
                cancelButtonText="Cambiar"
                text="Al cambiar tu ciudad, se vaciará tu carro de compras."
              />
            )}
          </div>
        </UserMenu>
      </Wrapper>
    </Layout>
  )
}

export default withPageTransition(MyAccount)
