import { useStaticQuery, graphql } from 'gatsby'
import { ContentfulImage, Filter, RichTextJson } from '../graphql/contentfulTypes'

type ContentfulGlobal = {
  maxLineBundleQuantity: number
  maxLineProductQuantity: number
  promotionCouponsWhitelist: number
  defaultCategoryFilters: Filter[]
  mainAlcoholCategoryName: string
  mainAlcoholCategorySlug: string
  alcoholCategories: string[]
  mainWineCategoryName: string
  mainWineCategorySlug: string
  wineCategories: string[]
  mainFoodCategoryName: string
  mainFoodCategorySlug: string
  foodCategories: string[]
  accountUpdateImage: ContentfulImage
  accountUpdateText: RichTextJson
  accountUpdateEnable: boolean
}

type QueryResult = {
  allContentfulGlobal: {
    edges: Array<{
      node: ContentfulGlobal
    }>
  }
}

const useContentfulGlobal = (): ContentfulGlobal => {
  const data = useStaticQuery<QueryResult>(graphql`
    query ContentfulGlobal {
      allContentfulGlobal {
        edges {
          node {
            maxLineBundleQuantity
            maxLineProductQuantity
            promotionCouponsWhitelist
            defaultCategoryFilters {
              name
              label
              filter
            }
            mainAlcoholCategoryName
            mainAlcoholCategorySlug
            alcoholCategories
            mainWineCategoryName
            mainWineCategorySlug
            wineCategories
            mainFoodCategoryName
            mainFoodCategorySlug
            foodCategories
            accountUpdateImage {
              title
              file {
                url
              }
            }
            accountUpdateText {
              json
            }
            accountUpdateEnable
          }
        }
      }
    }
  `)

  return data?.allContentfulGlobal?.edges?.[0]?.node
}

export default useContentfulGlobal
