import {
  City,
  TextField,
  capitalize,
  Anchor,
  Select,
  Button,
  toMonthName,
  InfoMessage,
  useAuth,
} from '@ecommerce/shared'
import React, { useState } from 'react'

import { Controller, useFormContext } from 'react-hook-form'
import { navigate } from 'gatsby'

interface Props {
  isLoading: boolean
  cities: City[]
  onCityChange: (selected: string) => {}
  currentSlug: string
  cssPrefix: string
  bdate: {
    date: string
    month: string
    year: string
  }
  globalQuantity: number
  apiError: string | null
  setAlertState: ({ isOpenSignOut, isOpenSubmit }: { isOpenSignOut: boolean; isOpenSubmit: boolean }) => void
  alertState: {
    isOpenSignOut: boolean
    isOpenSubmit: boolean
  }
  handleSubmit: () => void
}

const EditChile = ({
  alertState,
  setAlertState,
  apiError,
  cssPrefix,
  cities,
  isLoading,
  bdate,
  currentSlug,
  onCityChange,
  globalQuantity,
  handleSubmit,
}: Props) => {
  const methods = useFormContext()

  const { state: authState } = useAuth()
  const { city } = authState

  const [selectedCity, setSelectedCity] = useState(city || 0)

  const { control, formState, getValues } = methods

  const { dirtyFields, isValid } = formState

  return (
    <>
      <div className={`${cssPrefix}container-bottom`}>
        <div className={`${cssPrefix}container-bottom-left`}>
          <Controller
            control={control}
            name="firstName"
            render={({ value, onChange }) => (
              <TextField
                value={capitalize(value)}
                label="Nombre"
                disabled
                className={`${cssPrefix}container-bottom-left-field`}
                onChange={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name="lastName"
            render={({ value, onChange }) => (
              <TextField
                label="Apellido"
                value={capitalize(value)}
                disabled
                className={`${cssPrefix}container-bottom-left-field`}
                onChange={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name="dni"
            render={({ value, onChange }) => (
              <TextField
                label="Rut"
                value={value}
                disabled
                className={`${cssPrefix}container-bottom-left-field`}
                onChange={onChange}
              />
            )}
          />

          <Controller
            control={control}
            name="email"
            render={({ value, onChange }) => (
              <TextField
                label="Correo Electrónico"
                value={value}
                disabled
                className={`${cssPrefix}container-bottom-left-field`}
                onChange={onChange}
              />
            )}
          />
        </div>
        <div className={`${cssPrefix}container-bottom-right`}>
          <div className={`${cssPrefix}password-container`}>
            <TextField label="Contraseña" readOnly value="****" />
            <div className={`${cssPrefix}password-reset`}>
              <Anchor
                className={`${cssPrefix}reset-link`}
                onClick={() => (!isLoading ? navigate(`/${currentSlug}/new-password`) : null)}
              >
                <span>Restablecer</span>
              </Anchor>
            </div>

            <Controller
              control={control}
              name="city"
              render={() => (
                <Select
                  className={`${cssPrefix}city-selector`}
                  label="Ciudad"
                  placeholder={cities.find((ct) => ct.id === city)?.name || ''}
                  onSelect={(selected) => onCityChange(selected)}
                  options={Object.values(cities).map((val) => ({
                    value: val.id,
                    label: val.name,
                  }))}
                />
              )}
            />

            <div className={`${cssPrefix}birthdate-container`}>
              <div className={`${cssPrefix}birthdate-label`}>
                <span>Fecha de nacimiento</span>
              </div>
              <div className={`${cssPrefix}birthdate-fields`}>
                <TextField value={bdate.date} disabled className={`${cssPrefix}birthdate-fields-date`} />
                <TextField
                  value={capitalize(toMonthName(parseInt(bdate.month, 10)))}
                  disabled
                  className={`${cssPrefix}birthdate-fields-month`}
                />
                <TextField value={bdate.year} disabled className={`${cssPrefix}birthdate-fields-year`} />
              </div>
            </div>
            <div className={`${cssPrefix}submit-container`}>
              <Button
                isLoading={isLoading}
                disabled={isLoading || !getValues().city || getValues().city === selectedCity}
                isDisabled={Object.keys(dirtyFields).length === 0 || !isValid}
                className={`${cssPrefix}submit-button`}
                onClick={() => {
                  return globalQuantity > 0 ? setAlertState({ ...alertState, isOpenSubmit: true }) : handleSubmit()
                }}
              >
                Guardar cambios
              </Button>
            </div>
            <div className={`${cssPrefix}error-status`}>
              <InfoMessage className={`${cssPrefix}message`} isHidden={!apiError} message={apiError || undefined} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default EditChile
