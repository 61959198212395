import React, { HTMLAttributes } from 'react'
import styled from 'styled-components'
import { breakpoints } from '@ecommerce/shared'

const cssPrefix = 'PageContainer__'

const Wrapper = styled.div`
  margin-top: 72px;
  margin-bottom: 0px;
  padding: 0px;
  max-width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  .${cssPrefix} {
    &container {
      min-height: calc(100vh - 231px);
      width: 100%;
      background: ${(props) => props.theme.colors.medium};
      @media (${breakpoints.tabletPortrait.min}) {
        min-height: calc(100vh - 194px);
      }
      @media (${breakpoints.desktop.min}) {
        min-height: calc(100vh - 212px);
        display: flex;
        justify-content: center;
        padding: 36px 105px 36px 105px;
      }
    }
  }
`

const Card = styled.div<{ cardMinWidthDesktop?: string; cardWidthDesktop?: string; cardMaxWidthDesktop?: string }>`
  width: 100%;
  min-height: calc(100vh - 234px);
  display: flex;
  justify-content: center;
  padding: 32px 32px 126px 32px;
  background: ${(props) => props.theme.colors.white};

  @media (${breakpoints.tabletPortrait.min}) {
    min-height: calc(100vh - 206px);
  }

  @media (${breakpoints.desktop.min}) {
    box-shadow: ${(props) => props.theme.boxShadow.lvlOne};
    min-height: 100%;
    height: 100%;
    width: ${(props) => (props.cardWidthDesktop ? props.cardWidthDesktop : props.theme.sizes.width.desktopMin)};
    max-width: ${(props) => (props.cardMaxWidthDesktop ? props.cardMaxWidthDesktop : 'initial')};
    min-width: ${(props) => (props.cardMinWidthDesktop ? props.cardMinWidthDesktop : '997px')};
  }
`

interface PageContainerProps extends HTMLAttributes<HTMLDivElement> {
  cardMinWidthDesktop?: string
  cardWidthDesktop?: string
  cardMaxWidthDesktop?: string
}

export const PageContainer = (props: PageContainerProps) => {
  const { children, ...restProps } = props
  return (
    <div>
      <Wrapper>
        <div className={`${cssPrefix}container`}>
          <Card className={`${cssPrefix}card`} {...restProps}>
            {children}
          </Card>
        </div>
      </Wrapper>
    </div>
  )
}
